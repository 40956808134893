<template>
  <div class="page-padding text-content !pt-28">
    <p>Thank you for your order, you will soon receive an e-mail!</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>