<template>
  <div v-if="about" class="page-padding">
    <div v-html="about.content" class="text-content leading-snug"></div>
      <a :href="about.button_link_url" target="_blank" class="inline-block">
          <div class="button" :style="about.button_styling" v-html="about.button_text">
          </div>
      </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      about: null
    }
  },
  mounted() {
    fetch(`${process.env.VUE_APP_BASE_URL}cms/api/entries?id=about&token=522ed707c8c8490f117c3d037998f601`)
    .then((response) => response.json())
    .then((data) => {
      this.about = data.data;
    })
  }
}
</script>

<style>

</style>