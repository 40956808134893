<template>
    <div class="page-padding text-content !pt-28">
        <p>You cancelled the payment. <router-link to="/">Click here</router-link> to go back to the homepage.</p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>